import * as React from 'react'
import isNode from 'is-node'
import queryString from 'query-string'
import algoliasearch from 'algoliasearch'

import { Props, State } from './types'

const getDefaultFilter = () => ({
  category: [],
  country: [],
  goes_well_with: [],
  grape: [],
  producer: [],
  region: [],
  sustainable: [],
  assortment: [],
  price: [],
  search: '',
})

const GATSBY_ALGOLIA_APP_ID = process.env.GATSBY_ALGOLIA_APP_ID || ''
const GATSBY_ALGOLIA_KEY = process.env.GATSBY_ALGOLIA_API_KEY || ''
const GATSBY_ALGOLIA_PRODUCTS_INDEX =
  process.env.GATSBY_ALGOLIA_PRODUCTS_INDEX || ''
const GATSBY_ALGOLIA_ARTICLES_INDEX =
  process.env.GATSBY_ALGOLIA_ARTICLES_INDEX || ''
const GATSBY_ALGOLIA_COUNTRIES_INDEX =
  process.env.GATSBY_ALGOLIA_COUNTRIES_INDEX || ''
const GATSBY_ALGOLIA_PRODUCERS_INDEX =
  process.env.GATSBY_ALGOLIA_PRODUCERS_INDEX || ''
const GATSBY_ALGOLIA_REGIONS_INDEX =
  process.env.GATSBY_ALGOLIA_REGIONS_INDEX || ''
const GATSBY_PRODUCTS_PER_PAGE = process.env.GATSBY_PRODUCTS_PER_PAGE || 24

const sortOptions = [
  { value: 'default', title: 'Rekommenderat' },
  { value: 'price', title: 'Pris' },
]

class ProductsContainer extends React.Component<Props, State> {
  client = algoliasearch(GATSBY_ALGOLIA_APP_ID, GATSBY_ALGOLIA_KEY)

  /**
   * Set ids so we always filter by the products sent from gatsby
   * Set first state to the products recieved by gatsby
   * Fetch all facets so we can show our filter
   */
  constructor(props: Props) {
    super(props)
    const { products, productIds } = props

    this.state = {
      loading: false,
      products: products,
      allFacets: null,
      availableFacets: {},
      ids: productIds ? productIds : products.map((p: any) => p.objectID),
      filter: getDefaultFilter(),
      showFilter: true,
      page: 0,
      sort: 'default',
      gridType: 'two',
      articles: [],
      producers: [],
      countries: [],
      regions: [],
      totalHits: productIds ? productIds.length : products.length,
      noHits: false,
    }

    this.fetchFacets()
  }

  fetchNextPage = async () => {
    const nextPage = this.state.page + 1
    this.setState({ page: nextPage }, () => {
      this.updateHash()
    })
  }

  updateSort = async (value: 'default' | 'price') => {
    this.setState({ sort: value, page: 0 }, () => {
      this.updateHash()
    })
  }

  fetchPosts = async (): Promise<void> => {
    const { isSearchPage, isSearchBar, searchWord } = this.props
    if (isSearchPage || isSearchBar) {
      let searchString = isSearchBar ? searchWord : ''

      const hash = queryString.parse(window.location.hash)
      if (!isSearchBar) {
        if (hash.search && typeof hash.search === 'string') {
          searchString = hash.search
        }
      }

      const queries = [
        {
          indexName: GATSBY_ALGOLIA_ARTICLES_INDEX,
          query: searchString,
          params: {
            hitsPerPage: isSearchBar ? 4 : 12,
          },
        },
        {
          indexName: GATSBY_ALGOLIA_COUNTRIES_INDEX,
          query: searchString,
          params: {
            hitsPerPage: isSearchBar ? 2 : 6,
          },
        },
        {
          indexName: GATSBY_ALGOLIA_PRODUCERS_INDEX,
          query: searchString,
          params: {
            hitsPerPage: isSearchBar ? 4 : 12,
          },
        },
        {
          indexName: GATSBY_ALGOLIA_REGIONS_INDEX,
          query: searchString,
          params: {
            hitsPerPage: isSearchBar ? 2 : 6,
          },
        },
      ]

      let articles: any[] = []
      let producers: any[] = []
      let countries: any[] = []
      let regions: any[] = []

      const response = await this.client.multipleQueries(queries)

      if (response && response.results) {
        response.results.forEach((result: any) => {
          if (result.index === 'articles') {
            articles = result.hits
          }
          if (result.index === 'producers') {
            producers = result.hits
          }
          if (result.index === 'countries') {
            countries = result.hits
          }
          if (result.index === 'regions') {
            regions = result.hits
          }
        })
      }

      // const response = await index.search(searchString, {
      //   hitsPerPage: isSearchBar ? 5 : GATSBY_PRODUCTS_PER_PAGE,
      // })

      // const { hits } = response

      this.setState({ articles, producers, countries, regions })
    } else {
      return null
    }
  }

  /**
   * Fetch all the facets that can be used for the product id's we receieved
   * We do this to prevent them from disappearing from the UI when we use our facets
   */
  fetchFacets = async (): Promise<void> => {
    try {
      this.setState({ loading: true })
      const { isSearchPage, perPage, isSearchBar } = this.props
      const index = this.client.initIndex(GATSBY_ALGOLIA_PRODUCTS_INDEX)
      let searchString = ''
      if (isSearchPage && !this.state.noHits) {
        const hash = queryString.parse(window.location.hash)
        if (hash.search && typeof hash.search === 'string') {
          searchString = hash.search
        }
      }

      // Create a filter to search for the specific products facets
      let filters = ''
      if (!isSearchPage) {
        this.state.ids.forEach((id: string, index: number) => {
          filters = filters + `objectID:${id}`
          if (this.state.ids.length > index + 1) {
            filters = filters + ' OR '
          }
        })
      }

      const facetsResponse = await index.search(searchString, {
        facets: ['*'],
        filters,
        hitsPerPage: perPage ? perPage : GATSBY_PRODUCTS_PER_PAGE,
      })

      const { facets, facets_stats, hits, nbHits } = facetsResponse
      if (facets_stats && facets_stats.price && facets && facets.price) {
        facets.price = {
          ...facets.price,
          ...facets_stats.price,
        }
      }

      if (isSearchPage || isSearchBar) {
        if (nbHits && nbHits > 0) {
          this.setState(
            {
              loading: false,
              allFacets: facets,
              products: hits,
              totalHits: nbHits,
            },
            () => {
              this.fetchPosts()
            }
          )
        } else {
          this.setState(
            {
              loading: false,
              noHits: true,
              filter: { ...this.state.filter, search: '' },
            },
            () => {
              this.fetchFacets()
            }
          )
        }
      } else {
        this.setState({ allFacets: facets })
      }
    } catch (error) {
      this.setState({ loading: false })
    }
  }

  /**
   * Create a facetFilter with the filters we have in our state
   * Documentation: https://www.algolia.com/doc/api-reference/api-parameters/facetFilters/
   *
   * THen use the response to set availableFacets and products state
   */
  fetchProducts = async (): Promise<void> => {
    const { perPage } = this.props
    try {
      this.setState({ loading: true })
      const indexName =
        this.state.sort === 'price'
          ? `${GATSBY_ALGOLIA_PRODUCTS_INDEX}_price`
          : GATSBY_ALGOLIA_PRODUCTS_INDEX
      const index = this.client.initIndex(indexName)
      let filters = ''
      this.state.ids.forEach((id: string, index: number) => {
        filters = filters + `objectID:${id}`
        if (this.state.ids.length > index + 1) {
          filters = filters + ' OR '
        }
      })

      if (this.state.filter.price && this.state.filter.price.length > 0) {
        filters =
          filters +
          ` AND price:${this.state.filter.price[0]} TO ${this.state.filter.price[1]}`
      }

      const facetFilters: any[] = []
      Object.keys(this.state.filter).forEach((key: string) => {
        if (key !== 'price' && key !== 'search') {
          if (this.state.filter[key].length > 1) {
            const multiFilter: any[] = []
            this.state.filter[key].forEach((slug: string) => {
              multiFilter.push(`${key}:${slug}`)
            })
            facetFilters.push(multiFilter)
          } else {
            facetFilters.push(`${key}:${this.state.filter[key]}`)
          }
        }
      })

      const response = await index.search(
        this.state.filter.search ? this.state.filter.search : '',
        {
          facets: ['*'],
          filters,
          facetFilters,
          page: this.state.page,
          hitsPerPage: perPage ? perPage : GATSBY_PRODUCTS_PER_PAGE,
        }
      )

      this.setState({
        products:
          this.state.page === 0
            ? response.hits
            : [...this.state.products, ...response.hits],
        availableFacets: response.facets,
        totalHits: response.nbHits,
        loading: false,
      })
    } catch (error) {
      this.setState({ loading: false })
    }
  }

  filterIsEmpty(obj: any): boolean {
    for (const key in obj) {
      if (obj.hasOwnProperty(key)) return false
    }
    return true
  }

  componentDidMount(): void {
    if (!isNode) {
      const hash = queryString.parse(window.location.hash)
      const startState = {
        page: 0,
        sort: 'default',
      }
      if (hash.page && typeof hash.page === 'string') {
        startState.page = parseInt(hash.page)
      }
      if (hash.sort && typeof hash.sort === 'string') {
        startState.sort = hash.sort
      }
      if (!this.filterIsEmpty(JSON.parse(JSON.stringify(hash)))) {
        this.setState({ page: startState.page, sort: startState.sort }, () => {
          this.onHashChange()
        })
      }
    }
  }

  componentWillReceiveProps(nextProps: any): void {
    const { isSearchPage, searchWord } = this.props
    if (isSearchPage && searchWord !== nextProps.searchWord) {
      this.setState(
        {
          filter: {
            ...getDefaultFilter(),
            search: nextProps.searchWord,
          },
          noHits: false,
        },
        () => {
          this.fetchFacets()
          this.updateHash()
        }
      )
    }
    if (!isSearchPage && searchWord !== nextProps.searchWord) {
      this.setState(
        {
          filter: {
            ...getDefaultFilter(),
            search: nextProps.searchWord,
          },
          noHits: false,
        },
        () => {
          this.fetchPosts()
          this.fetchProducts()
        }
      )
    }
  }

  /**
   * If filter is valid
   * then add / replace it to the state.
   */
  onFilterChange = ({ facet, value }: any): void => {
    if (this.state.filter[facet]) {
      if (facet === 'search') {
        this.replaceRemoveFilter(facet, value)
      } else {
        this.addRemoveFilter(facet, value)
      }
    }
  }

  /**
   * If filter is valid, checks if it accept multiple options,
   * then add / replace it to th e state.
   */
  onRangeFilterChange = ({ facet, value }: any): void => {
    if (this.state.filter[facet]) {
      this.replaceRemoveFilter(facet, value)
    }
  }

  /**
   * If hash changes, set the next filter for our state and then fetch products
   */
  onHashChange = (): void => {
    const fromHash = queryString.parse(window.location.hash)
    const nextFilter = getDefaultFilter()
    Object.keys(fromHash).forEach((categoryKey) => {
      if (nextFilter.hasOwnProperty(categoryKey)) {
        let value = fromHash[categoryKey]
        if (categoryKey === 'search') {
          nextFilter[categoryKey] = value
        } else {
          if (typeof value === 'string') {
            value = value.split(',')
          }
          if (Array.isArray(value)) {
            nextFilter[categoryKey] = value
          }
        }
      }
    })

    if (nextFilter.search && nextFilter.search[0]) {
      nextFilter.search === nextFilter.search[0]
    }

    this.setState(
      {
        filter: nextFilter,
      },
      () => {
        this.fetchProducts()
      }
    )
  }

  /**
   * Add / Remove filter - Use this for filters that accept multiple options
   */
  addRemoveFilter = (type: string, slug: string | null) => {
    this.setState((prevState: State) => {
      let category = prevState.filter[type]
      if (slug === null) {
        category = []
      } else {
        const index = category.indexOf(slug)
        if (index === -1) {
          category.push(slug)
        } else {
          category.splice(index, 1)
        }
      }
      if (type === 'price') {
        category = []
      }

      const nextFilter = Object.assign(prevState.filter, { [type]: category })
      if (nextFilter.search && nextFilter.search[0]) {
        nextFilter.search === nextFilter.search[0]
      }
      prevState.page = 0
      return Object.assign(prevState, nextFilter)
    }, this.updateHash)
  }

  replaceRemoveFilter = (type: string, value: string) => {
    this.setState((prevState: State) => {
      let category = prevState.filter[type]
      if (value === null) {
        category = []
      } else {
        category = value
      }
      const nextFilter = Object.assign(prevState.filter, { [type]: category })
      if (nextFilter.search && nextFilter.search[0]) {
        nextFilter.search === nextFilter.search[0]
      }
      prevState.page = 0
      return Object.assign(prevState, nextFilter)
    }, this.updateHash)
  }

  updateHash(): void {
    const currentHash = window.location.hash
    const { filter } = this.state
    filter.page = this.state.page
    filter.sort = this.state.sort
    const nextHash = queryString.stringify(filter, {
      arrayFormat: 'none',
      encode: false,
    })

    if (!isNode && currentHash !== nextHash) {
      window.history.pushState(null, '', `#${nextHash}`)
      this.onHashChange()
    }
    /*
    const scrollDestination = this.filterNode.offsetTop - 73
    if (this.filterNode && scrollDestination > window.scrollY) {
      scrollTo(scrollDestination)
    }
    */
  }

  render() {
    const { render } = this.props
    const {
      products,
      loading,
      allFacets,
      availableFacets,
      filter,
      totalHits,
      showFilter,
      sort,
      gridType,
      articles,
      producers,
      countries,
      regions,
      noHits,
    } = this.state

    const isFiltered = Object.keys(filter)
      .filter((key: string) => key !== 'search')
      .find((key: string) => filter[key].length > 0)
      ? true
      : false

    return render ? (
      render({
        noHits,
        isFiltered,
        loading,
        products,
        allFacets,
        filter,
        availableFacets,
        totalHits,
        showFilter,
        sort,
        gridType,
        articles,
        producers,
        countries,
        regions,
        fetchNextPage: this.fetchNextPage,
        onFilterChange: this.onFilterChange,
        onRangeFilterChange: this.onRangeFilterChange,
        setShowFilter: (value: boolean) => {
          this.setState({ showFilter: value })
        },
        setSort: this.updateSort,
        setGrid: (value: string) => {
          this.setState({ gridType: value })
        },
        sortOptions,
        resetFilter: () => {
          this.setState(
            {
              filter: {
                ...getDefaultFilter(),
                search: this.state.filter.search,
              },
              page: 0,
            },
            () => {
              this.updateHash()
            }
          )
        },
      })
    ) : (
      <div />
    )
  }
}

export default ProductsContainer
